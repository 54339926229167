<template>
  <AutoComplete
      v-model="internalModel"
      :suggestions="filteredStaffs"
      :forceSelection="forceSelection"
      @complete="searchStaff"
      field="name"
      :minLength=3
      placeholder="Search staff..."
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String],
      default: () => {
        return ""
      }
    },
    forceSelection: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    internalModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (typeof newValue === 'string' && !this.isStaffInList(newValue)) {
          const customValue = {staff_id: null, name: newValue};
          this.$emit('input', customValue);
        } else {
          this.$emit('input', newValue);
        }
      }
    }
  },
  data() {
    return {
      filteredStaffs: []
    };
  },
  methods: {
    async searchStaff(event) {
      if (!event.query.trim()) return;

      try {
        let results = await this.StaffService.searchStaff(event.query, {
          select: "staff_id,first_name,last_name",
        });

        let response = [];
        if (results && results.data) {
          response = results.data.map((staff) => ({
            staff_id: staff.staff_id,
            name: `${staff.first_name} ${staff.last_name}`,
          }));
        }
        this.filteredStaffs = response;
      } catch (error) {
        console.error("Error fetching staffs:", error);
      }
    },
    isStaffInList(name) {
      return this.filteredStaffs.some(staff => staff.name === name);
    }
  }
};
</script>

<style>
/* Your styles */
</style>
